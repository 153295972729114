.about-us {
  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }

  &__image {
    position: relative;
    width: calc(50% - 10rem);

    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 5rem;
    }

    &:before {
      content: '';
      position: absolute;
      top: -2rem;
      left: -2rem;
      width: 100%;
      height: 100%;
      background-color: var(--green);
      transition: .3s ease-in-out;

      @media (max-width: 768px) {
        top: -1rem;
        left: -1rem;
      }
    }

    &:hover {
      &:before {
        top: 2rem;
        left: 2rem;
        background-color: var(--light-green);

        @media (max-width: 768px) {
          top: 1rem;
          left: 1rem;
        }
      }
    }

    img {
      position: relative;
      aspect-ratio: 1 / 1;
      box-shadow: 0 10px 20px 0 var(--light-green);
      cursor: pointer;
    }
  }

  &__info {
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
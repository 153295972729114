.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 4rem;
  border: 1px solid var(--white);
  border-radius: 3rem;

  font-family: inherit;
  font-size: 1.8rem;
  font-weight: 200;
  color: var(--white);
  text-transform: uppercase;
  letter-spacing: .2rem;

  background-color: transparent;

  @media (max-width: 768px) {
      padding: 1rem 3rem;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css');

* {
  box-sizing: border-box;
}

:root {
  --black: #000000;
  --white: #ffffff;
  --green: #014341;
  --light-green: #0B7875;
  --light-grey: #c9c9c9;
}

::-webkit-scrollbar {
  width: 10px;
  border: 1px solid var(--green);
  background-color: var(--green);
}
::-webkit-scrollbar-thumb {
  background-color: var(--light-green);
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  color: var(--green);
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2;
}

main {
  padding-top: 10rem;

  @media (max-width: 1025px) {
    padding-top: 8rem;

    overflow: hidden;
  }
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

button {
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
}

ul {
  list-style: none;
  margin: 0;
  margin-block: 0;
  padding-inline: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

// Typography

h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: 'Montserrat';
  font-weight: 700;
  color: inherit;
  line-height: 1.4;
}

h1 {
  margin-bottom: 1.4rem;
  font-size: 7.8rem;
  line-height: 1.125;
  text-align: center;
  letter-spacing: .5rem;

  @media (max-width: 768px) {
    font-size: 4.8rem;
  }
}

h2 {
  margin-bottom: 4rem;
  font-size: 3.6rem;

  @media (max-width: 768px) {
    margin-bottom: 2rem;
    font-size: 3rem;
  }
}

h3 {
  margin-bottom: 2rem;
  font-size: 2.2rem;
  letter-spacing: .2rem;

  @media (max-width: 768px) {
      font-size: 2rem;
  }
}

h4 {
  margin-bottom: 1.5rem;
  font-size: 1.8rem;

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
}

p {
  margin-top: 0;
  margin-bottom: 2rem;
  color: var(--green);

  @media (max-width: 768px) {
      margin-bottom: 1rem;
  }
}


.form {
  display: block;

  &__animate {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    backdrop-filter: blur(4px);
    opacity: 0;
    animation: .4s pullDown forwards;
    z-index: 0;

    h4 {
      margin-top: 1rem;
    }

    span {
      position: relative;
      display: block;
      width: 15rem;
      height: 15rem;
      background-repeat: no-repeat;
      background-size: 50% 50%, 50% 50%;
      background-position: 0 0, 100% 0, 100% 100%, 0 100%;
      background-image: linear-gradient(white, white), linear-gradient(black, black), linear-gradient(white, white), linear-gradient(black, black);
      border-radius: 50%;
      transition: .4s ease-in;
      animation: 1s spin infinite;
      animation-delay: .5s;

      &:before {
        content: '';
        position: absolute;
        top: -.1rem;
        left: -.1rem;
        right: -.1rem;
        bottom: -.1rem;
        border-radius: 50%;
        background-color: var(--black);
        animation: 1s fade forwards;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(.75);
        display: block;
        width: 10rem;
        height: 10rem;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
        transition: .2s ease-in;
      }
    }

    &_success {
      span {
        background-image: none;
        background: green;
        box-shadow: 0 0 2rem .3rem green;
        animation: none;
        &:before {
          opacity: 0;
        }

        &:after {
          background-image: url('assets/images/icons/UI/success.svg');
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;
        }
      }
    }

    &_denied {
      span {
        background-image: none;
        background: red;
        box-shadow: 0 0 2rem .3rem red;
        animation: none;

        &:before {
          opacity: 0;
        }

        &:after {
          background-image: url('assets/images/icons/UI/denied.svg');
          background-size: 55%;
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;
        }
      }
    }
  }

  &_send {
    .form__animate {
      transform: translateY(0);
      opacity: 1;
      z-index: 3;
    }
  }
}


@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pullDown {
  from {
    opacity: 0;
    transform: translateY(-20%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade {
  from {
    transform: scale(1.2);
  }

  to {
    transform: scale(.96);
  }
}
.footer {
  margin-top: 5rem;
  padding: 10rem 0;

  background-color: var(--green);

  color: var(--white) !important;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  &__info {
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;
    }

    .nav {
      ul {
        margin-bottom: 2rem;
      }
    }
  }

  &__map {
    width: 48%;
    aspect-ratio: 16 / 9;

    @media (max-width: 768px) {
      width: 100%;
      margin-top: 5rem;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &__contacts {
    margin-bottom: 5rem;
  }
}
.nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  transition: .3s ease-in-out;

  ul{
    display: flex;
    flex-wrap: wrap;
    margin-right: 2rem;

    li {
      color: var(--white);

      &:not(:last-of-type) {
        margin-right: 2.5rem;
      }

      a {
        letter-spacing: .2rem;
      }
    }
  }
}
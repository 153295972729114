.socials {
  display: flex;
  width: fit-content;

  .social-link {
    margin-right: 2rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
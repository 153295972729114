.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.3);
  backdrop-filter: blur(20px);
  z-index: 9999;
  animation: opacity .4s;

  &__content {
    position: relative;
    padding: 2.5rem 2.5rem 4.5rem;
    width: calc(100% - 4rem);
    max-width: 40rem;
    background: var(--white);
    box-shadow: 0 1.7rem 1.2rem 0 #00000040;
    border-radius: .8rem;
    opacity: 0;
    transform: translateY(50%);
    animation: pullUp .4s forwards;
    animation-delay: .3s;

    .button {
      color: var(--green);
    }

    p {
      text-align: left!important;
    }

    form {
      margin-top: 2.5rem;

      input {
        margin-bottom: 2rem;
        width: 100%;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      button {
        margin-top: 2.5rem;
        width: 100%;
      }
    }
  }

  &__close {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    z-index: 3;
    cursor: pointer;
    background: transparent;
    transition: .3s ease-in-out;


    &:hover {
      transform: rotate(90deg);
    }

    &:focus {
      transform: rotate(90deg) scale(1.4);
    }
  }
}

@keyframes opacity {
  from {
    opacity: 0;
    pointer-events: none;
  }

  to {
    opacity: 1;
    pointer-events: all;
  }
}

@keyframes pullUp {
  from {
    opacity: 0;
    transform: translateY(50%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.services {
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10rem;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      margin-top: 4rem;
    }
  }

  &__logo {
    width: 40%;

    @media (max-width: 768px) {
      width: 100%;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    position: relative;

    height: 50vh;
    width: 50%;

    @media (max-width: 768px) {
      height: 70vh;
      width: 100%;
      margin-bottom: 5rem;
    }

    &:before {
      content: '';

      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;

      width: 100%;
      height: 10rem;

      background: linear-gradient(0deg, rgba(255,255,255, .8) 0%, rgba(255,255,255,0.7091211484593838) 47%, rgba(255,255,255,0) 100%);

      @media (max-width: 768px) {
        height: 5rem;
      }
    }
  }

  &__accordions {
    padding-right: 5rem;
    padding-bottom: 10rem;

    max-height: 100%;
    overflow-y: auto;

    @media (max-width: 768px) {
      padding-bottom: 5rem;
      padding-right: 2rem;
    }

    &::-webkit-scrollbar {
      width: 3px;

      background-color: transparent;
      border: none;
    }
  }

  &__accordion {
    &-header {
      position: relative;

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 2rem;
      border-bottom: 1px solid;
      border-color: var(--white);

      cursor: pointer;
      transition: .4s ease-in-out;

      p {
        margin-bottom: 0;
        width: calc(100% - 3rem);

        font-size: 2rem;
        letter-spacing: .3rem;
        text-transform: uppercase;

        transition: .4s ease-in-out;

        @media (max-width: 768px) {
          font-size: 1.6rem;
        }
      }
    }

    &-content {
      max-height: 0;

      overflow: hidden;
      transition: .5s ease-in-out;
    }

    &-arrow {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      transition: .4s ease-in-out;

      @media (max-width: 768px) {
        height: fit-content;

        img {
          height: auto;
          width: 20px;
        }
      }
    }

    &.active {
      .services {
        &__accordion {
          &-header {
            margin-bottom: 2rem;

            border-color: var(--green);
          }

          &-content {
            max-height: 20rem;
          }

          &-arrow {
            transform: rotate(180deg) translateY(100%);
          }
        }
      }
    }
  }
}
.section {
  padding: 7.5rem 0;

  @media (max-width: 768px) {
      padding: 5rem 0;
  }

  &_small {
    padding: 4rem 0;
  }
}
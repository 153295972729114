.social-link {
    width: 4rem;
    height: 4rem;
    background-color: var(--white);
    border-radius: 10rem;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    img {
        width: 2rem;
    }
}
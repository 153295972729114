.form-field {
  position: relative;

  width: 90%;
  margin: 0 auto 2.5rem;

  border-bottom: 1px solid var(--green);

  &:last-of-type {
    margin-bottom: 0;
  }

  &__input {
    position: relative;
    padding: 1rem;
    width: 100%;
    font: inherit;
    color: var(--green)!important;
    border: none;
    transition: .3s ease-in-out;
    z-index: 2;
    outline: none;
    box-shadow: none;

    @media (max-width: 450px) {
      width: 80%;
    }
  }

  &_error {
    border-bottom: 1px solid red;

    .form-field__error-text {
      opacity: 1;
      transform: translateY(100%);
    }
  }

  &__error-text {
    position: absolute;
    bottom: 0;
    left: 1rem;
    opacity: 0;
    font-size: 1.1rem;
    color: red;
    transition: .3s ease-in-out;
  }
}
.section-header {
  position: relative;
  margin: 0 auto;
  width: fit-content;
  text-transform: uppercase;

  &__big-text {
    font-size: 6rem;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: .1rem var(--green);
  }

  h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 900;
    letter-spacing: .2rem;
  }
}
.portfolio-grid {
  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 541px) {
        grid-template-columns: repeat(1, 1fr);
    }
  }

  &__item {
    position: relative;

    overflow: hidden;

    &:hover {
      .portfolio-grid__info {
        transform: translateY(0);

        opacity: 1;
      }
    }
  }

  &__info {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);

    width: 100%;
    height: 100%;

    padding: 2rem;

    background: rgba(1, 67, 65, .8);
    backdrop-filter: blur(5px);

    transition: .4s ease-in-out;
    opacity: 0;

    h4,
    p {
      color: var(--white);
    }
  }

  &__image {
    display: flex;
    aspect-ratio: 4 / 3;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  }

  &__nav {
    margin: 5rem 0;

    @media (max-width: 768px) {
      margin: 3rem 0;
    }

    ul {
      display: flex;

      @media (max-width: 1024px) {
        padding: 1rem 0;
        width: 98vw;

        overflow-y: scroll;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      li {
        flex: none;
        border: 1px solid var(--green);

        border-radius: 3rem;

        cursor: pointer;
        transition: .3s ease-in-out;

        &:not(:last-of-type) {
          margin-right: 2rem;

          @media (max-width: 768px) {
              margin-right: 1rem;
          }
        }

        &.active {
          background-color: var(--green);

          h4 {
            color: var(--white);
          }
        }

        h4 {
          margin-bottom: 0;

          transition: .3s ease-in-out;
        }

        input {
          display: none;
        }

        label {
          display: block;
          width: 100%;
          height: 100%;
          padding: 1rem 4rem;

          cursor: pointer;

          @media (max-width: 1024px) {
            padding: 1rem 2rem;
          }
        }
      }
    }
  }
}
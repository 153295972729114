.header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 10rem;
  z-index: 20;
  background-color: var(--green);
  transition: .3s ease-in;

  @media (max-width: 1025px) {
    height: 8rem;

    .nav {
      position: absolute;
      top: 0;
      right: calc(-100% - 5rem);

      flex-direction: column;
      align-items: flex-start;
      width: fit-content;
      height: 100vh;
      padding: 8rem 3rem;

      background-color: var(--light-green);
      box-shadow: 0 10px 20px 0 var(--green);

      &_open {
        right: 0;
      }

      .button {
        margin-top: auto;
      }

      ul {
        flex-direction: column;

        li {
          margin-bottom: 2rem;
        }
      }
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &_scrolled {
    height: 8rem;
    background: rgba(1, 67, 65, .95);

    .header__logo {
      a {
        img{
          width: 8rem;

          @media (max-width: 768px) {
            width: 7rem;
          }
        }
      }
    }
  }

  &__logo {
    a {
      display: block;

      img {
        width: 10rem;
        transition: .4s ease-in-out;

        @media (max-width: 1025px) {
          width: 7rem;
        }
      }
    }
  }
}
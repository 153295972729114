.team-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin: 5rem auto;
  width: 80%;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 541px) {
      grid-template-columns: repeat(1, 1fr);
      width: 100%;
  }

  &__item {
    background: var(--light-green);
    box-shadow: 0 10px 20px 0 var(--light-grey);

    img {
      aspect-ratio: 4 / 3;
      width: 100%;
    }

    &-info {
      padding: 1.5rem 3rem;

      @media (max-width: 768px) {
        padding: 1.5rem 2rem;
      }

      h4, h3 {
        color: var(--white);
      }

      p {
        color: var(--white);
      }
    }
  }
}
.welcome {
  position: relative;

  display: flex;
  justify-items: center;
  align-items: center;
  height: calc(100vh - 10rem);
  padding: 0;

  text-align: center;

  @media (max-width: 1025px) {
      height: calc(100vh - 8rem);
  }
  
  &__inner {
    position: relative;

    color: var(--white);

    .socials {
      margin: 3rem auto 0;
    }
  }

  &__slider {
    position: absolute!important;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    .slick-list, .slick-track, .slick-slide, .slick-slide div {
      position: relative;

      height: 100%;

      &:before {
        content: '';

        position: absolute;
        top: 0;
        left: 0;

        height: 100%;
        width: 100%;

        background-color: rgba(0, 0, 0, .58);
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .scroll-down {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
  }
}
.burger {
  background: transparent;

  -webkit-tap-highlight-color: rgba(0,0,0,0);

  @media (min-width: 1026px) {
    display: none;
  }

  span {
    display: block;
    height: 2px;
    width: 22px;

    background-color: var(--white);

    transition: .3s ease-in-out;

    &:not(:last-of-type) {
      margin-bottom: 7px;
    }
  }

  &_active {
    span {
      &:first-of-type {
        transform: rotate(45deg) translate(5px, 7.5px);
      }

      &:nth-of-type(2) {
        transform: translateX(-100%);

        opacity: 0;
      }

      &:last-of-type {
        transform: rotate(-45deg) translate(5px, -7.5px);
      }
    }
  }
}
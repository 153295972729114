.scroll-down {
  &__inner {
    display: flex;
    align-items: center;

    width: fit-content;
    transform: rotate(-90deg);
  }

  &__trigger {
    position: relative;
    height: 2rem;
    width: 6rem;
    border: .1rem solid var(--white);
    border-radius: 2rem;

    div {
      position: absolute;
      top: .3rem;
      right: .3rem;
      height: calc(100% - .6rem);
      width: 2rem;
      border-radius: 2rem;
      background-color: var(--white);
      animation: teleport 3s ease infinite;
    }
  }
}

@keyframes teleport {
  0% {
    right: .3rem;
    transform: translateX(0);
  }

  50% {
    right: calc(100% - .3rem);
    transform: translateX(100%);
  }

  100% {
    right: .3rem;
    transform: translateX(0);
  }
}